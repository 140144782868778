import React from 'react'

import { BasicLayout } from '../../../../components/BasicLayout'
import { AuthRoute } from '../../../../components/auth/AuthRoute'
import { SettingsPrivacyContent } from '../../../../components/settings/SettingsComplianceDetails/SettingsPrivacyContent'

const SettingsPrivacyPage = () => {
  return (
    <AuthRoute>
      <BasicLayout>
        <SettingsPrivacyContent />
      </BasicLayout>
    </AuthRoute>
  )
}

export default SettingsPrivacyPage
export const Head = () => <title>Datacapt - Settings</title>
