import './SettingsPrivacyConsentModal.less'

import { Form } from 'antd'
import React, { useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { CustomConsent, addCustomConsent, updateCustomConsent } from '../../../../../requests'
import { DatacMessage, DatacModal } from '../../../../common'
import { EditorIcons, EditorInput } from '../../../../shared/Editor'

interface Props {
  onClose: () => void
  isOpened: boolean
  consent: CustomConsent
}

export const SettingsPrivacyConsentModal: React.FC<Props> = ({ isOpened, onClose, consent }) => {
  const intl = useScopedIntl('')
  const intlModal = useScopedIntl('settings.privacy.modal')
  const [formInstance] = Form.useForm()
  const [isAddingConsent, setIsAddingConsent] = useState(false)

  const getRequestFunction = () => {
    if (consent) return updateCustomConsent
    return addCustomConsent
  }

  const onSubmit = ({ body }: CustomConsent) => {
    setIsAddingConsent(true)
    getRequestFunction()(
      { body, id: consent?.id },
      {
        onSuccess: () => {
          setIsAddingConsent(false)
          onClose()
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsAddingConsent(false)
        }
      }
    )
  }

  return (
    <DatacModal
      isOpened={isOpened}
      onClose={onClose}
      afterClose={() => formInstance.resetFields()}
      title={intlModal(consent ? 'title.edit' : 'title.add')}
      onSubmit={() => formInstance.submit()}
      loading={isAddingConsent}
      destroyOnClose
    >
      <Form
        form={formInstance}
        name="settings-privacy-consent-form"
        className="settings-privacy-consent-modal__form"
        onFinish={onSubmit}
      >
        <EditorInput
          fieldName="body"
          label={intlModal('consent.label')}
          initialValue={consent?.body}
          enabledIcons={[EditorIcons.Bold, EditorIcons.Link, EditorIcons.Undo]}
          placeToolbarOnBottom
        />
      </Form>
    </DatacModal>
  )
}
